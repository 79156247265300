<template>
  <!-- <div class="hello">
    <v-btn @click="$router.push('/hehe')"> to hehe</v-btn>
    <v-btn @click="login">获取</v-btn>
    <v-btn @click="$cookies.remove('access_token')">清除</v-btn>
  </div>-->
  <v-layout max-width>
    <div class="login flex-center flex-column max-width" style="height:100vh;">
      <div class="flex-1 flex-column flex-center max-width position-relative" style=" z-index:2;">
        <div
          class="flex-justify-between"
          style="min-height:550px;box-shadow:0 0 6px 1px #ddd;background:#fff;"
        >
          <div
            class="grey lighten-5 flex-shrink-0 radius-0 flex-column flex-center py-4 px-6"
            style="border-right: 1px dashed #eee !important;width:400px;padding:16px 24px;"
          >
            <img class src="@/assets/logo3.png" width="200">
            <div
              class="mb-2 pb-2 mt-2 flex-center"
              style="margin:8px 0;padding-bottom:8px; border-bottom: 1px dashed rgb(221, 221, 221);"
            >
              <img class src="@/assets/logo4.png" width="300">
            </div>
            <div
              class="font-weight-bold title mb-4 mt-6"
              style="margin-bottom:8px;margin-bottom: 16px;font-weight:700;font-size:1.25rem;line-height:2rem;"
            >CTMS后台管理系统</div>
            <p
              class="body-2 grey--text"
              style="text-indent: 2em;font-size:1em;"
            >以临床试验项目为中心进行延伸拓展，包括FTE、项目中心管理、质量、受试者、报告、SAE、文档等管理，使管理者方便及时的掌握试验进展情况。</p>
            <!-- <div
              class="body-2 mt-12 grey--text"
              style="margin-top:48px;font-size:0.85rem;"
            >v1.2.06.22-beta-20200101</div> -->
          </div>
          <div
            class="py-4 px-10 flex-shrink-0 flex-center"
            style="padding:32px 40px;min-width:600px;"
            min-width="700"
          >
            <div class="flex-center flex-1" style>
              <form class="flex-column max-width" @submit.prevent="submit">
                <!-- margin-bottom:8px; -->
                <div
                  class="headline font-weight-bold"
                  style="font-weight:700;font-size:1.5rem;line-height:2rem;margin-bottom:8px;"
                >找回密码</div>
                <div
                  class="body-2 mb-4 grey--text"
                  style=""
                >
                  <v-stepper v-model="stepValue" alt-labels flat class="elevation-0">
                    <!--  -->
                    <v-stepper-header class="elevation-0">
                      <v-stepper-step :complete="stepValue > 1" step="1" color="#1890ff">
                        账号信息
                      </v-stepper-step>
                      <v-divider />
                      <v-stepper-step :complete="stepValue > 2" step="2" color="#1890ff">
                        邮箱验证码
                      </v-stepper-step>
                      <v-divider />
                      <v-stepper-step :complete="stepValue > 3" step="3" color="#1890ff">
                        重置密码
                      </v-stepper-step>
                    </v-stepper-header>
                  </v-stepper>

                </div>
                <div style="width:500px;margin:auto;">
                  <!-- <div
                    class="flex-justify-between default-input"
                    style="margin-bottom:28px;height:50px;"
                  >

                    <input
                      v-model="form.username"
                      required
                      class="flex-1 subtitle-1 max-height"
                      placeholder="原密码"
                      style="height:100%;padding-left:24px;"
                      @keyup.enter="submit"
                    >
                  </div>
                  <div
                    class="flex-justify-between default-input"
                    style="height:50px;margin-bottom:28px;"
                  >
                    <input
                      v-model="form.password"
                      required
                      class="flex-1 subtitle-1"
                      type="password"
                      placeholder="新密码"
                      style="height:100%;padding-left:24px;"
                      @keyup.enter="submit"
                    >
                  </div>
                  <div
                    class=" flex-1 flex-justify-between default-input"
                    style="height:50px;margin-bottom:28px;"
                  >
                    <input
                      v-model="form.captcha"
                      required
                      class="flex-1 subtitle-1"
                      type="text"
                      placeholder="确认新密码"
                      style="height:100%;padding-left:24px;"
                      @keyup.enter="submit"
                    >
                  </div> -->
                  <div class="flex-x-center flex-column pa-12">
                    <template v-if="stepValue === 1">
                      <v-text-field v-model="form.email" required dense outlined placeholder="输入邮箱" />
                      <div class="flex-justify-between flex-y-center mb-12">
                        <v-text-field v-model="form.captcha" required hide-details dense outlined placeholder="验证码" />
                        <div class="flex-0 flex-shrink-0 ml-4" @click="getRandomImage">
                          <img width="100" height="40" :src="randomImage">
                        </div>
                      </div>
                      <span class=" red--text">提示：正确完整填写表单内容，系统会将验证码发送至您的电子邮箱，请注意查收！</span>
                    </template>
                    <template v-else-if="stepValue === 2">
                      <v-text-field v-model="form.captcha" required outlined dense placeholder="输入验证码" />
                      <!-- <span class=" red--text">验证码已发送至邮箱，有效期5分钟。请注意查收!</span> -->
                      <span class=" red--text">{{ msg }}</span>
                      <!-- <div v-if="second" class="grey--text text--darken-3 text-center">{{ second }} 秒后失效</div>
                      <a v-else @click="sendCaptchaToMail()">重新获取</a> -->
                    </template>
                    <template v-else-if="stepValue === 3">
                      <div
                        class="flex-justify-between default-input"
                        style="margin-bottom:28px;height:50px;"
                      >
                        <input
                          v-model="form.password"
                          required
                          class="flex-1 subtitle-1 max-height"
                          placeholder="设置新密码"
                          style="height:100%;padding-left:10px;"
                          type="password"
                        >
                      </div>
                      <div
                        class="flex-justify-between default-input"
                        style="margin-bottom:28px;height:50px;position: relative;"
                      >
                        <input
                          v-model="form.surePassword"
                          required
                          class="flex-1 subtitle-1 max-height"
                          placeholder="确认新密码"
                          style="height:100%;padding-left:10px;"
                          type="password"
                          @blur="blurSurePassword"
                        >
                        <span v-if="noSame" class=" error--text" style="position: absolute; left:14px;top:54px;">密码不一致</span>
                      </div>
                    </template>
                    <template v-else-if="stepValue === 4">
                      <div class=" flex-center" style="margin-bottom:28px;height:50px;">
                        密码重置成功！
                      </div>
                    </template>
                  </div>
                  <div class=" flex-1 flex-center mb-2 mt-4 max-width px-12" style="">
                    <v-btn
                      class=" flex-1"
                      depressed
                      style="height:44px;border:none;background:#1890ff;color:#fff;"
                      @click="$router.replace({path:'/login'})"
                    >返回登录</v-btn>
                    <v-btn
                      v-if="stepValue!=4"
                      depressed
                      :loading="btnLoading"
                      class=" flex-1 ml-4"
                      style="height:44px;border:none;background:#1890ff;color:#fff;"
                      type="submit"
                    >提交</v-btn>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
import { mixinList } from './mixinList'
export default {
  name: 'Login',
  mixins: [mixinList],
  data() {
    return {
      stepValue: 1,
      form: {
        username: '',
        password: '',
        remember_me: false,
        checkKey: ''
      },
      btnLoading: false,
      time: '',
      // second: 60,
      noSame: false,
      msg: ''
    }
  },
  mounted() {
    this.getRandomImage()
  },
  methods: {
    // 用户-发送验证码到邮箱
    sendCaptchaToMail() {
      const param = new URLSearchParams()
      param.append('email', this.form.email)
      param.append('captcha', this.form.captcha)
      param.append('captchaKey', this.form.checkKey)
      this.btnLoading = true
      this.$http.post(this.$api.sendCaptchaToMail, { data: param }).then(res => {
        this.btnLoading = false
        this.msg = res.result
        this.form.captcha = ''
        this.form.checkKey = ''
        this.stepValue = 2
      }).catch(() => {
        this.getRandomImage()
        this.btnLoading = false
      })
    },
    // 用户-找回密码-校验验证码
    checkPasswordCaptcha() {
      this.btnLoading = true
      const param = new URLSearchParams()
      param.append('email', this.form.email)
      param.append('captcha', this.form.captcha)
      this.$http.post(this.$api.checkPasswordCaptcha, { data: param }).then(res => {
        this.stepValue = 3
        this.btnLoading = false
        this.$message.success(res.result)
      }).catch(() => {
        this.btnLoading = false
      })
    },
    // 用户-找回密码-重设密码
    resetPassword() {
      this.blurSurePassword()
      if (!this.noSame) {
        this.btnLoading = true
        const param = new URLSearchParams()
        param.append('email', this.form.email)
        param.append('password', this.form.password)
        this.$http.post(this.$api.resetPassword, { data: param }).then(res => {
          this.stepValue = 4
          this.btnLoading = false
          this.$message.success(res.result)
        }).catch(() => {
          this.btnLoading = false
        })
      }
    },
    // 失去焦点
    blurSurePassword(e) {
      if (this.form.surePassword !== this.form.password) {
        this.noSame = true
      } else {
        this.noSame = false
      }
    },
    // 下一步
    async submit() {
      if (this.stepValue === 1) {
        this.sendCaptchaToMail()
      } else if (this.stepValue === 2) {
        this.checkPasswordCaptcha()
      } else {
        this.resetPassword()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.login {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      filter: blur(0);
      background-position: center center;
      background-size: cover;
      background-image: url("../../assets/login-background1.jpg");
      /*background-color: #031337;*/
      transition: all .75s ease;
    }

    &::before {
      filter: blur(3px);
    }
  }

  .login-form {
    width: 600px;
    /*width: 100%;*/
    padding: 0 16px;
    z-index: 2;
  }
.login-main {
  min-width: 260px;
  width: 500px;
  margin: 0 auto;
  padding: 20px 40px;
  background-color: #fff;
}
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
    line-height: 40px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}
</style>
